.term-and-conditions-main{
    margin-top: 131px;
}
.privacy-section-main{
    margin-top: 131px;
}
.privacy-policy-section {
    margin-top: 50px;
}

.privacy-policy-body p,
li {
    font-size: 14px;
}

.privacy-policy-heading {
    color: #000000;
    margin-top: 50px;
    font-size: 40px;
    font-weight: 600;
}

.privacy-policy-time {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

.privacy-policy-text {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

.middle-heading {
    font-size: 14px;
    font-weight: bold;
}