.introduction-section {
    background-image: url("../../assets/images/backgroundRectangle.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto 113%;
    min-height: calc(100vh - 131px);
    margin-top: 131px;
}

.motility-disorders {
    font-size: 20px;
    font-weight: 400;
    margin-top: 50px;
    color: #000000;
}

.score-main {
    line-height: 2;
}

.distress-score-main {
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

.distress-score {
    color: #0D5565;
    font-size: 20px;
    font-weight: 600;
}

.distress-score-inner {
    color: #0D5565;
    font-size: 16px;
    font-weight: 600;
}

.gutc-score-main {
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

.gutc-score {
    color: #0D5565;
    font-size: 20px;
    font-weight: 600;
}

.distress-core-inner {
    color: #0D5565;
    font-size: 16px;
    font-weight: 600;
}

.bowel-sound {
    color: #0D5565;
    font-size: 20px;
    font-weight: 600;
}

.absoulte-screens {
    position: absolute;
    right: 40px;
    display: flex;
    justify-content: end;
    top: 300px;
    gap: 70px;
}

.first-screen-img {
    box-shadow: 1.6494312286376953px 4.948293685913086px 21.44260597229004px 0px #0000005C;
}

.subtitleMedical {
    border: 2px solid #0D5565;
    padding: 16px 10px;
    margin-top: 40px;
}

.subtitleParagraph {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
}

@media (max-width:767px) {
    .introduction-section{
        background-image: none;
    }
}