.explanation-section {
    background-image: url("../../assets/images/rectangle1.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto 117%;
    min-height: calc(100vh - 131px);
    margin-top: 131px;
}

.endoLogic-explanation-text {
    font-size: 20px;
    font-weight: 400;
    margin-top: 50px;
    color: #000000;
}

.card-wrapper {
    margin-top: 50px !important;
}

.custom-card {
    width: '20rem' !important;
    background: #FFFFFF;
    box-shadow: 5.6921px 10.5711px 17.0763px -3.25263px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.heading-green-zone {
    background-color: #4CD964 !important;
    color: #0D5565;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: center;
}

.heading-yellow-zone {
    background-color: #FFD000 !important;
    color: #0D5565;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: center;
}

.heading-red-zone {
    background-color: #E11A16 !important;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: center;
}

.card-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

.endoLogic-about-text {
    font-size: 24px;
    font-weight: 400;
    margin-top: 50px;
    color: #000000;
}

.green-zone {
    font-weight: 600;
    font-size: 24px;
    color: #0D5565;
}

.subtitleMedical-explanations {
    border: 2px solid #0D5565;
    padding: 16px 10px;
    margin-top: 40px;
}

.subtitleParagraph {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
}

@media (max-width:767px) {
    .explanation-section{
        background-image: none;
    }
}