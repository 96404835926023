

.active{
    color: #0D5565 !important;
    border-bottom: 2px solid #0D5565 !important;
}
.term-conditions-privacy{
    display: flex;
    gap: 70px;
    margin: 0;
    padding: 30px  0;
}
.term-conditions{
text-decoration: none;
color: #000;
cursor: pointer;
font-size: 14px;
font-weight: 400;
}
.term-conditions:hover{
    text-decoration: none;
    color: #0D5565;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    }
.privacy-policy{
    text-decoration: none;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
} 

.privacy-policy:hover{
text-decoration: none;
color: #0D5565;
cursor: pointer;
font-size: 14px;
font-weight: 400;
}
@media (max-width:767px){
    .term-conditions-privacy{
        gap: 30px;
        justify-content: center;
    }
    .term-conditions:hover{
        color: #ffffff;
        }
        .privacy-policy:hover{
            color: #ffffff;
            }
}