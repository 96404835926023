.navbar{
    position: fixed;
    width: 100%;
    top:0;
    z-index: 10001;
}
.active{
 border-bottom: 2px solid black !important;
}
.nav-link{
    font-size: 14px;
  }
.navbar{
 padding: 40px;
 box-shadow: 0px 3px 14px -3px rgba(0, 0, 0, 0.13);
 background-color: #ffffff !important;
}
.nav-brand-logo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 30px;
    text-decoration: none;
}
.logo-text{
    font-size: 36px;
    color: #0D5565;
    margin-left: 11px;
}


.navbar-nav{
    width: 100%;
    gap: 114px;
}
@media (max-width:1400px){
    .navbar-nav{
        gap: 100px;
    } 
}
@media (max-width:1300px){
    .navbar-nav{
        gap: 80px;
    } 
}

@media (max-width:1200px){
    .navbar-nav{
        gap: 60px;
    } 
}
@media (max-width:1100px){
    .navbar-nav{
        gap: 30px;
    } 
}
@media (max-width:1000px){
    .navbar-nav{
        gap: 15px;
    } 
}
@media (max-width:767px){
    .navbar-nav{
        gap: 15px;
    } 
}

