.about-section {
    background-image: url("../../assets/images/backgroundRectangle.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto 113%;
    min-height: calc(100vh - 131px);
    margin-top: 131px;
}

.endoLogic-about-text {
    font-size: 24px;
    font-weight: 400;
    margin-top: 50px;
    color: #000000;
}

.endoLogic-about-second-paragraph {
    font-size: 24px;
    font-weight: 400;
    margin-top: 50px;
    color: #000000;
}

.endologic-web-link {
    font-size: 24px;
    font-weight: 400;
    display: flex;
    gap: 12px;
    color: #0D5565;
    display: inline;
}

.endologic-web-link:hover {
    color: #0D5565;
}

.subtitleMedical-about {
    border: 2px solid #0D5565;
    padding: 16px 10px;
    margin-top: 60px;

}

.subtitleParagraph {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
}
@media (max-width:767px){
    .vector-img img {
        height: 200px !important;
        width: 200px !important;
    } 
    .about-section{
     background-image: none !important;
    } 
}