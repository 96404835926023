.contact-us-main-section {
    background-image: url("../../assets/images/backgroundRectangle.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto 150%;
    min-height: calc(100vh - 131px);
    margin-top: 131px;
}

.heading {
    color: #000000;
    margin-top: 85px;
    font-size: 60px;
    font-weight: 600;
}

.address {
    display: flex;
    align-items: center;
    gap: 12px;
}

.address::before {
    background-image: url('../../assets/images/map.png');
    display: inline-block;
    width: 22px;
    height: 26px;
    content: "";
    background-size: cover;
}

.email-customer-service {
    display: flex;
    gap: 12px;
    color: #0D5565;
}

.email-customer-service:hover {
    color: #0D5565;
}

.email-customer-service::before {
    background-image: url('../../assets/images/envelope.png');
    display: inline-block;
    width: 24px;
    height: 20px;
    content: "";
    background-size: cover;
}

.contact-form-wrapper {
    margin-top: 60px;
}

.contact-form {
    display: flex;
    margin-top: 11px;
    gap: 20px;
}

.input-group {
    flex-direction: column;
}

.input-group label {
    color: #0D5565;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
}

.input-group input {
    height: 40px;
    border: 1px solid #249BB6;
    border-radius: 50px !important;
    padding: 3px 20px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
}

.input-group textarea {
    border: 1px solid #249BB6;
    border-radius: 50px !important;
    padding: 7px 20px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
}

.submit-button {
    margin-top: 54px;
}

.submit-btn {
    padding: 12px 124px;
    background: #249BB6;
    border-radius: 50px;
    outline: none;
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: #ffff;
}

@media (max-width:1000px) {
    .contact-us-main-section {
        background-size: auto 300%;
    }

    .submit-btn {
        padding: 12px 60px;
    }

    .input-group label {
        color: #ffffff;
    }

    .email-customer-service {
        display: flex;
        gap: 12px;
        color: #ffffff;
    }

    .email-customer-service:hover {
        color: #ffffff;
    }
}
@media (max-width:700px) {
    .contact-us-main-section {
        background-image: none;
    }

    .submit-btn {
        padding: 12px 60px;
    }

    .email-customer-service {
        display: flex;
        gap: 12px;
        color: #0D5565;
    }

    .email-customer-service:hover {
        color: #0D5565;
    }
    .input-group label {
        color:  #0D5565;
    }


}