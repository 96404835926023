.introduction-section {
    background-image: url("../../assets/images/backgroundRectangle.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto 112%;
    min-height: calc(100vh - 131px);
    margin-top: 131px;
}

.gastrointestinal-tract {
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    color: #000000;
}

.vector-img {
    position: absolute;
    width: 600px;
    right: 1px;
    display: flex;
    justify-content: end;
    top: 340px;
    gap: 70px;
}


.subtitleMedical-info {
    border: 2px solid #0D5565;
    padding: 16px 10px;
    margin-top: 20px;

}

.subtitleParagraph {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
}

@media (max-width:1200px) {
    .vector-img img {
        height: 300px !important;
        width: 300px !important;
    }
}

@media (max-width:767px) {
    .introduction-section{
        background-image: none !important;
    }
    .vector-img {
        top: 400px;
    }

    .vector-img img {
        height: 150px !important;
        width: 150px !important;
    }
}

@media (max-width:400px) {
    .vector-img img {
        height: 100px !important;
        width: 100px !important;
    }
}