.main-section {
    background-image: url("../../assets/images/backgroundRectangle.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto 113%;
    min-height: calc(100vh - 131px);
    margin-top: 131px;
}

.heading {
    color: #000000;
    margin-top: 85px;
    font-size: 60px;
    font-weight: 600;
}

.cutc-main-paragraph {
    font-size: 14px;
    font-weight: 400;
    margin-top: 50px;
    color: #000000;
}

.cutc-second-paragraph {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
}

.body-logo {
    position: absolute;
    top: 220px;
    right: 0px;
}

.apple-google-btn {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-top: 34px;
}

.subtitleMedical {
    border: 2px solid #0D5565;
    padding: 16px 10px;
    margin-top: 40px;

}

.subtitleParagraph {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
}

@media (max-width:1200px) {
    .body-logo {
        height: 200px !important;
        width: 200px !important;
    }

}

@media (max-width:1000px) {
    .heading {
        font-size: 30px;
    }
}

@media (max-width:767px) {
    .main-section {
    background-image: none;
    }
    .body-logo {
        height: 100px !important;
        width: 100px !important;
    }

}