.term-condition-heading {
    color: #000000;
    margin-top: 50px;
    font-size: 40px;
    font-weight: 600;
}

.term-condition-text {
    font-size: 14px;
    font-weight: 400;
}

.site-transections {
    font-size: 14px;
    font-weight: 700;
}